import React from "react";
import "../styles/footer.css";

const Footer = () => {
  return (
    <div>
      <nav className="navbar footer navbar-bottom">
      </nav>
    </div>
  );
};

export default Footer;
